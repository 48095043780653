import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './BannerCarousel.css'

import 'react-lazy-load-image-component/src/effects/blur.css';


const settings = {
  dots: false,
  fade: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  slidesToScroll: 2,

};

export default () => (

  <Slider {...settings}>
    <div >

      <img width="100%" effect="blur" alt="Image" className="w-full " src="eyeimage/home/slides/bnner1.jpeg" placeholderSrc="Image/BannerSlider/san1.png" />
    </div>
    <div >

<img width="100%" effect="blur" alt="Image" className="w-full " src="eyeimage/home/slides/banner2.jpeg" placeholderSrc="Image/BannerSlider/san1.png" />
</div>

    <div >

      <img width="100%" effect="blur" alt="Image" className="w-full " src="eyeimage/home/slides/GKB-HomePage-Desktop-Banner-1.webp" placeholderSrc="Image/BannerSlider/san1.png" />
    </div>
    <div>
      <img width="100%" effect="blur" alt="Image" className="w-full" src="eyeimage/home/slides/Prescription_banner_desktop.webp" placeholderSrc="Image/BannerSlider/san1.png" />
    </div>
    <div>
      <img width="100%" effect="blur" alt="Image" className="w-full" src="eyeimage/home/slides/Champ_Banner.webp" placeholderSrc="Image/BannerSlider/san1.png" />
    </div>
    <div>
      <img width="100%" effect="blur" alt="Image" className="w-full" src="eyeimage/home/WebBanner.webp" placeholderSrc="Image/BannerSlider/san1.png" />
    </div>


  </Slider>
);


