
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ThankYouPage = () => {
  const location = useLocation();
  const [orderId, setOrderId] = useState('');

  useEffect(() => {
    if (location.state.orderId) {
      setOrderId(location.state.orderId);
    }
  }, [location.state.orderId]);

  return (
    <div className='mt-40 mb-20 itmem-center justify-center mx-auto text-center'>
      <h1 className='text-2xl '>Thank You For Your Order!</h1>
      <div className='bg-green-400 text-2xl lg:hidden mx-auto my-6  px-4 py-4 rounded-full w-20 text-white'>✔</div>

      <div className='flex itmem-center justify-center gap-4 ' >
        <div className='bg-green-400 text-2xl hidden lg:block px-4 py-4 rounded-full text-white'>✔</div>
        <p className='my-auto md:flex mx-6 lg:mx-0 text-2xl'>Your order has been successfully placed with order ID: <p className='text-3xl ml-2 font-bold text-green-600'>{orderId}</p></p>
      </div>
      <p className='text-yellow-600'> We will contact you soon </p>

    </div>
  );
};

export default ThankYouPage